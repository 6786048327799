import axios from "axios";
import SDK from "../../config";

// Ensure all imports are placed before any function or logic
const postVisitorsData = async ({ email, lead, addtocart, purchase, amount,tags }) => {
  try {
    const isAndroid = false;
    const response = await axios.put(`${SDK.SOCKET_URL}/api/iOS/post/webvisitors`, {
      email,
      lead,
      addtocart,
      purchase,
      amount,
      isAndroid,
      tags
    });

    return response.data; 
  } catch (error) {
    console.error("Error posting visitor data:", error);
    throw error; 
  }
};

export default postVisitorsData;
